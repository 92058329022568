import React, { useRef, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { flipInY, slideInLeft, slideInRight, zoomIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import viewportAction from "viewport-action";
import { useIsVisible } from 'react-is-visible'
ReactGA.initialize("G-K9SVV8GQNZ");

const options = {
    // How long the handler will wait after the original event stops triggering.
    // wait: 100,
    // Whether to unbind the handler after executed for the first time.
    once: true,
};


var Landed = true,
    Home = true,
    about = true,
    Mint = true,
    Roadmap = true,
    Community = true;

export default function ScaryTeacher() {
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [category, setCategory] = useState("mint.znkgames.com");

    const ques1 = useRef();
    const ques2 = useRef();
    const ques3 = useRef();
    const ques4 = useRef();
    const ques5 = useRef();

    const phase1 = useIsVisible(ques1, { once: true });
    const phase2 = useIsVisible(ques2, { once: true });
    const phase3 = useIsVisible(ques3, { once: true });
    const phase4 = useIsVisible(ques4, { once: true });
    const phase5 = useIsVisible(ques5, { once: true });

    useEffect(() => setTimeout(() => setIsPageLoaded(true), 3000), []);
    useEffect(() => {
        if (isPageLoaded) {
            console.log("page loaded");
            attachEvents();
        }
    }, [isPageLoaded]);

    const attachEvents = () => {
        viewportAction.add(
            "#container",
            function (e) {
                if (Landed) {
                    ReactGA.event({
                        category: category,
                        action: "1:  Landed on Page",
                        label: window.location.ref,
                    });
                    console.log("Landed on Page");
                    Landed = false;
                }
            },
            options
        );
        viewportAction.add(
            "#home",
            function (e) {
                if (Home) {
                    ReactGA.event({
                        category: category,
                        action: "2:  Showing Home",
                        label: window.location.ref,
                    });
                    console.log("Start Showing:1");
                    Home = false;
                    // Remove handler
                    // e.removeHandler();
                }
            },
            options
        );
        viewportAction.add("#about", function (e) {
            if (about) {
                ReactGA.event({
                    category: category,
                    action: "3:  Showing About",
                    label: window.location.ref,
                });
                console.log("Start Showing:2");
                about = false;
            }
        });
        viewportAction.add("#mint--section", function (e) {
            if (Mint) {
                ReactGA.event({
                    category: category,
                    action: "4:  Showing Mint",
                    label: window.location.ref,
                });
                console.log("Start Showing:3");
                Mint = false;
            }
        });
        viewportAction.add("#roadmap", function (e) {
            if (Roadmap) {
                ReactGA.event({
                    category: category,
                    action: "5:  Showing Roadmap",
                    label: window.location.ref,
                });
                console.log("Start Showing:4");
                Roadmap = false;
            }
        });
        viewportAction.add("#community", function (e) {
            if (Community) {
                ReactGA.event({
                    category: category,
                    action: "6:  Showing Community",
                    label: window.location.ref,
                });
                console.log("Start Showing:5");
                Community = false;
            }
        });
    };

    const homeRef = useRef();
    const goToHome = () => {
        homeRef.current.scrollIntoView({ behavior: "smooth" });
        console.log("Clicked on Home");
        ReactGA.event({
            category: category,
            action: "  Click On Home",
            label: window.location.ref,
        });
    };
    const aboutRef = useRef();
    const goToAbout = () => {
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
        console.log("Clicked on About");
        ReactGA.event({
            category: category,
            action: "  Click On About",
            label: window.location.ref,
        });
    };
    const roadmapRef = useRef();
    const goToRoadmap = () => {
        roadmapRef.current.scrollIntoView({ behavior: "smooth" });
        console.log("Clicked on RoadMap");
        ReactGA.event({
            category: category,
            action: " Click On Roadmap",
            label: window.location.ref,
        });
    };
    const joinRef = useRef();
    const goToJoin = () => {
        joinRef.current.scrollIntoView({ behavior: "smooth" });
        console.log("Clicked on Join");
        ReactGA.event({
            category: category,
            action: " Click On Join",
            label: window.location.href,
        });
    };
    const playGame = () => {
        console.log("Clicked on Play Game");
        ReactGA.event({
            category: category,
            action: " Click On Play Game",
            label: window.location.href,
        });
    };
    const joinTheComunity = () => {
        joinRef.current.scrollIntoView({ behavior: "smooth" });
        console.log("Clicked on Join");
        console.log("Clicked on Join The Comunity");
        ReactGA.event({
            category: category,
            action: " Click On Join the Community",
            label: window.location.href,
        });
    };
    const swipe = () => {
        console.log("Clicked on Swipe Button");
        ReactGA.event({
            category: category,
            action: " Click On Swipe",
            label: window.location.href,
        });
    };
    const mintNow = () => {
        console.log("Clicked on Mint Now");
        ReactGA.event({
            category: category,
            action: " Click On Mint Now",
            label: window.location.href,
        });
        window.location="https://mint.scaryteachernfts.world/";
    };
    const discordIcon = () => {
        console.log("Clicked on Discord Icon");
        ReactGA.event({
            category: category,
            action: " Click On Discord",
            label: window.location.href,
        });
    };
    const facebookIcon = () => {
        console.log("Clicked on Facebook Icon");
        ReactGA.event({
            category: category,
            action: " Click On Facebook",
            label: window.location.href,
        });
    };
    const youtubeIcon = () => {
        console.log("Clicked on Youtube Icon");
        ReactGA.event({
            category: category,
            action: " Click On Youtube",
            label: window.location.href,
        });
    };
    const twitterIcon = () => {
        console.log("Clicked on Twitter Icon");
        ReactGA.event({
            category: category,
            action: " Click On Twitter",
            label: window.location.href,
        });
    };
    const privacyPolicy = () => {
        console.log("Clicked on Privacy Policy");
        ReactGA.event({
            category: category,
            action: " Click On Privacy Policy",
            label: window.location.href,
        });
    };
    const terms = () => {
        console.log("Clicked on Terms and Conditions");
        ReactGA.event({
            category: category,
            action: " Click On Terms and Conditions",
            label: window.location.href,
        });
    };

    const styles = {
        flipInY: {
            animation: '2s',
            animationName: Radium.keyframes(flipInY, 'flipInY')
        },
        slideInLeft: {
            animation: '2s',
            animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
        }
        , slideInRight: {
            animation: '2s',
            animationName: Radium.keyframes(slideInRight, 'slideInRight')

        },
        zoomIn: {
            animation: '2s',
            animationName: Radium.keyframes(zoomIn, 'zoomIn')

        }
    }
    return (
        <>
            <header>
                <div className="" id="container">
                    <nav className="navbar fixed-top navbar-expand-xl navbar-default py-xl-4">
                        <div className="container d-flex justify-content-between">
                            <ul className="nav d-none d-xl-flex">
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={discordIcon}
                                        href="https://discord.gg/Ptx8heAZZd"
                                        target="_blank"
                                    >
                                        <img src="img/ico-discord.svg" alt="..." />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={twitterIcon}
                                        href="https://twitter.com/TeacherScary"
                                        target="_blank"
                                    >
                                        <img src="img/ico-twitter.svg" alt="..." />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={youtubeIcon}
                                        href="https://www.youtube.com/channel/UC6z4RnDkI5DALyx3M1qEmPQ"
                                        target="_blank"
                                    >
                                        <img src="img/ico-youtube.svg" alt="..." />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={facebookIcon}
                                        href="https://www.facebook.com/Scary-Teacher-104953160859907"
                                        target="_blank"
                                    >
                                        <img src="img/ico-facebook.svg" alt="..." />
                                    </a>
                                </li>
                            </ul>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#mainMenu"
                                aria-controls="mainMenu"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="mainMenu">
                                <ul className="navbar-nav ml-auto font-outfit navbar__main text-center text-xl-left mb-4 mb-xl-0">
                                <li className="nav-item">
                                        <a className="nav-link" href="https://scaryteacher.gitbook.io/scary-teacher-nft-project/" target="_blank" role="button">
                                            WhitePaper
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={goToHome} role="button">
                                            Home
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={goToAbout} role="button">
                                            About
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={goToRoadmap} role="button">
                                            Roadmap
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={goToJoin} role="button">
                                            Join
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className="nav-link btn btn-success"
                                            // onClick={playGame}
                                            href="http://onelink.to/5unqss"
                                            target="_blank"
                                        >
                                            Play Game
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>

            <section className="overflow-hidden mb-5 " ref={homeRef} id="home">
                <div className="container">
                    <div className="row">
                        <div className="row align-items-center">
                            <div className="col-xl-6 order-xl-2">
                                <div className="px-3 pl-md-5 pr-md-0 overflow-hidden">
                                    <h1 className="font-frijole mt-5 mb-5 first__title">
                                        <span className="text-success">Scary</span> <br />
                                        Teacher
                                    </h1>
                                    <p className="h3 font-outfit mb-4 text-gray animate__animated animate__fadeInUp">
                                        Welcome to the
                                        <span className="text-white">
                                            {" "}
                                            World of Scary Teacher,
                                        </span>{" "}
                                        a hit game residing on mobile market. Miss T (aka Scary
                                        Teacher) has been threatening kids, giving physical
                                        punishment and at times torturing kids. She has a history of
                                        relocating in various neighbourhoods to keep an eye on her
                                        <span className="text-white"> ‘favourite students’.</span>
                                    </p>
                                    <p className="h3 mb-0 font-outfit text-gray animate__animated animate__fadeInUp">
                                        From the world of mobile phones, this time she has decided
                                        to enter the metaverse in search of new students!
                                    </p>
                                    <figure className="text-right mr-4">
                                        <img src="img/head-arrow.svg" alt="..." />
                                    </figure>
                                </div>
                            </div>
                            <div className="col-xl-6 order-xl-1">
                                <figure className="mb-0 my-5 first__pic">
                                    <img
                                        src="img/first-block.png"
                                        alt="..."
                                        className="img-fluid"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="overflow-hidden mb-5" ref={aboutRef} id="about">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7">
                            <h2 className="font-frijole text-success mb-5 mt-5 pt-4 second__title">
                                About Miss T
                            </h2>
                            <p className="h3 text-gray font-outfit mb-4 wow animate__animated animate__fadeInUp">
                                Main antagonist in our game is{" "}
                                <span className="text-white">Miss T,</span> a loud and obnoxious
                                yet quite dumb at times woman who has an unapologetically
                                selfish nature. If she has a passion for something, and she will
                                have it, no matter the price. There is a sleek arrogance to her
                                character whose self-love knows no bounds.{" "}
                                <span className="text-white">Miss T,</span> is seen as a cold,
                                cruel and extremely vain person who has it out for children in
                                particular. <br />
                                Lets join our hands together on our discord community to take
                                over the threat of <span className="text-white">Miss T,</span>
                            </p>
                            <p className="font-outfit mt-5 mb-5 text-mob-center">
                                <a
                                    onClick={joinTheComunity}
                                    role="button"
                                    href="https://discord.gg/Ptx8heAZZd"
                                    className="btn btn-success font-outfit large__btn"
                                >
                                    Join the Community
                                </a>
                            </p>
                        </div>
                        <div className="col-xl-5">
                            <figure className="mb-0 second__pic back">
                                <img
                                    width="70%"
                                    src="img/Rick.png"
                                    alt="..."
                                    className=""
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pl-0 pr-0 container-fluid pb-5" id="mint--section">
                <div className="">
                    <h2 className="font-frijole mb-5 text-center text-success third__title">
                        <span>
                            <span className="title__lines">NFTS AND METAVERSE</span>
                        </span>
                    </h2>
                    <div className="row mb-5">
                        <div className="col-md-10 offset-md-1">
                            <p className="h3 mb-5 font-outfit text-center wow animate__animated animate__fadeInUp">
                                Now, this
                                <span className="text-white"> Scary Teacher's</span> Teacher has
                                relocated to metaverse and planning to release 6,666 versions of
                                her. With so many<span className="text-white"> Miss Ts</span>{" "}
                                frolicking around the metaverse, we need to act fast! Its time
                                to tame the threat and own all
                                <span className="text-white"> NFTs </span>
                                of <span className="text-white"> Miss Ts</span> so that we can
                                teach them a lesson. Intelligence suggests that following are
                                some of the <span className="text-white"> NFTs </span> being
                                released by her:
                            </p>
                        </div>
                    </div>
                    <div className="carusel__block navbar-expand-xl">
                        <div className="swiper mySwiper ">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-first.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-01.png" alt="..." />
                                    </figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-second.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-02.png" alt="..." />
                                    </figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-third.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-03.png" alt="..." />
                                    </figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-fouth.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-04.png" alt="..." />
                                    </figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-first.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-01.png" alt="..." />
                                    </figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-second.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-02.png" alt="..." />
                                    </figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-third.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-03.png" alt="..." />
                                    </figure>
                                </div>
                                <div className="swiper-slide">
                                    <figure className="">
                                        <img src="img/bg-carusel-fouth.png" alt="..." />
                                        <img className="img-slider-size" src="img/teacher-04.png" alt="..." />
                                    </figure>
                                </div>
                            </div>
                        </div>

                        {/* <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div> */}
                    </div>
                    <p className="mt-5 mb-0 text-center">
                        <button
                            disabled
                            // onClick={mintNow}
                            className="btn btn-success font-outfit large__btn"
                        >
                            Mint: Coming Soon
                        </button>
                    </p>
                </div>
            </section>

            <section id="roadmap">
                <div className="" ref={roadmapRef}>
                    <h2 className="font-frijole my-5 text-center text-success third__title d-block d-lg-none">
                        <span>
                            <span className="title__lines">The RoadMap</span>
                        </span>
                    </h2>
                    <div className="container-fluid inner_roadmap">
                        <h2 className="font-frijole my-5 pt-5 text-center text-success third__title d-none d-lg-block">
                            <span>
                                <span className="title__lines">The RoadMap</span>
                            </span>
                        </h2>
                        <div className="row mb-5">
                            <div className="col-lg-4 order-lg-1 d-none d-lg-block">
                                <figure className="ml-xl-n5 mt-4 text-center">
                                    <img className="img-fluid" src="img/scaryteacher_logo.png" />
                                </figure>
                            </div>
                            <div className="col-lg-8 order-lg-0">
                                <div className="row mb-5">
                                    <div className="col-lg-4 col-5 order-lg-1">
                                        <div
                                            className={
                                                `arrow__first ${phase1 ? "wow animate__animated animate__flipInY" : "invisible"}`
                                            }>
                                            <img className="d-block d-lg-none" width="130px" src="img/Arrow2.png" alt="..." />
                                            <img className="d-none d-lg-block" width="250px" src="img/Arrow1.png" alt="..." />
                                            <h5 className="first-txt">Phase 1</h5>
                                        </div>
                                    </div>
                                    <div className="col-xl-7 offset-xl-1 col-lg-8 col-7 order-lg-0">
                                        <div className="row">
                                            <div className="col-lg-7 mb-4">
                                                <div
                                                    className={`${phase1 ? "wow animate__animated animate__slideInLeft" : "invisible"}`} >
                                                    <img className="img-fluid" src="img/Image1.png" alt="..." />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`${phase1 ? "wow animate__animated animate__zoomIn" : "invisible"}  `}>
                                            <span id="phase2"></span>
                                            <h3 className="font-frijole text-success text-uppercase">MARKETING &amp; MINTING</h3>
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <ul className="road__text font-outfit">
                                                        <li>Marketing, Partnerships and Collaborations</li>
                                                        <li>Minting of 333 NFTs for Cultists (OG)</li>
                                                        <li>Pre Sale of 1333 NFTs for Obedient Students (WL)</li>
                                                        <li>Playable demo for Pranking Miss T</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mb-5 road__block">
                            <div className="col-xl-7 offset-xl-5 col-lg-6 offset-lg-6">
                                <div ref={ques1} className="row mb-5">
                                    <div className="col-lg-3 col-5">
                                        <div 
                                            className={
                                                `arrow__second ${phase2 ? "wow animate__animated animate__flipInY" : "invisible"}`
                                            }>
                                            <img className="d-block d-lg-none" width="130px" src="img/Arrow2.png" alt="..." />
                                            <img className="d-none d-lg-block" width="250px" src="img/Arrow2.png" alt=".." />
                                            <h5 className="second-txt">Phase 2</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 offset-lg-1 col-7">
                                        <div className="row mb-5">
                                            <div className="col-xl-9">
                                                <div className={`${phase2 ? "wow animate__animated animate__slideInRight" : "invisible"}`}>
                                                    <img className="img-fluid" src="img/Image2.png" alt="..." />
                                                </div>
                                            </div>
                                        </div>

                                        <div 
                                            className={
                                                `${phase2 ? "wow ani animate__animated animate__zoomIn" : "invisible"}`
                                            }>
                                            <span id="phase3"></span>
                                            <h3 className="font-frijole text-success text-uppercase">P2E GAME</h3>
                                            <ul className="font-outfit road__text">
                                                <li>P2E Game: Prank Miss T Phase 2 Challenges</li>
                                                <li>Tease your NFT</li>
                                                <li>Coin launch (from early sale)</li>
                                                <li>Bonus Levels for higher P2E</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mb-5 road__block_secondy">
                            <div className="col-xl-6 offset-xl-1 col-lg-6">
                                <div ref={ques2} className="row mb-5">
                                    <div className="col-lg-4 col-5 order-lg-1">
                                        <div
                                            className={
                                                `arrow__third ${phase3 ? "wow animate__animated animate__flipInY" : "invisible"}`
                                            }>
                                            <img className="d-block d-lg-none" width="130px" src="img/Arrow2.png" alt="..." />
                                            <img className="d-none d-lg-block" width="250px" src="img/Arrow1.png" alt="..." />
                                            <h5 className="first-txt">Phase 3</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-7 order-lg-0">
                                        <div className="row mt-4">
                                            <div className="col-xl-8 offset-xl-1 col-lg-9">
                                                <div 
                                                    className={
                                                        `mb-5 res_mr_ques3 ${phase3 ? "wow animate__animated animate__slideInLeft" : "invisible"}`
                                                        
                                                    }>
                                                    <img className="img-fluid ml-xl-n5" src="img/Image3.png" alt="..." />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ml-xl-5">
                                            <div
                                                className={`font-outfit ${phase3 ? "wow animate__animated animate__zoomIn" : "invisible"} `}>
                                                <span id="phase4"></span>
                                                
                                                <div className="row">
                                                    <div className="col-lg-10 offset-lg-1">
                                                        <h3 className="font-frijole text-success text-uppercase">COIN CREATION</h3>
                                                        <ul className="road__text">
                                                            <li>Coin Creation + Offering</li>
                                                            <li>Tease your NFT</li>
                                                            <li>Coin launch (from early sale)</li>
                                                            <li>Bonus Levels for higher P2E</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row mb-5 road__block">
                            <div className="col-xl-6 offset-xl-6 col-lg-5 offset-lg-7">
                                <div ref={ques3} className="row mb-5">
                                    <div className="col-lg-4 col-5">
                                        <div 
                                            className={
                                                `arrow__fouth ${phase4 ? "wow ani animate__animated animate__flipInY" : "invisible"}`
                                            }>
                                            <img className="d-block d-lg-none" width="130px" src="img/Arrow2.png" alt="..." />
                                            <img className="d-none d-lg-block" width="250px" src="img/Arrow2.png" alt=".." />
                                            <h5 className="third-txt">Phase 4</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-7">
                                        <div className="row mt-4 mb-lg-5">
                                            <div className="col-lg-10 offset-lg-2 col-xl-8">
                                                <div 
                                                    className={
                                                        `mb-5 ${phase4 ? "wow ani animate__animated animate__slideInRight" : "invisible"}`
                                                    }>
                                                    <img className="img-fluid mb-5" src="img/Image4.png" alt="..." />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className={
                                                `mx-xl-5 px-xl-5 ${phase4 ? "wow animate__animated animate__zoomIn" : "invisible"}`
                                        }>
                                            <span id="phase5"></span>

                                            <h3 className="font-frijole text-success text-uppercase">STAKING</h3>
                                            <ul className="font-outfit road__text">
                                                <li>Scary Staking: Stake Coins and get APY/APR</li>
                                                <li>Add extra in-level prank options for NFT holders (which they can buy as NFT)</li>
                                                <li>Adding NFTs to other Miss T games like Scary Teacher</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row road__block_secondy">
                            <div className="col-xl-7 offset-xl-0 col-lg-6 offset-lg-2_">
                                <div ref={ques4} className="row mt-3">
                                    <div className="col-lg-4 col-5 order-lg-1">
                                        <div
                                            className={
                                                `arrow__fiveth ${phase5 ? "wow animate__animated animate__flipInY" : "invisible"}`
                                            }>
                                            <img className="d-block d-lg-none" width="130px" src="img/Arrow2.png" alt="..." />
                                            <img className="d-none d-lg-block" width="250px" src="img/Arrow1.png" alt="..." />
                                            <h5 className="fivht-txt">Phase 5</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-7 order-lg-0">
                                        <div className="row">
                                            <div className="col-lg-8 offset-lg-2">
                                                <div
                                                    className={
                                                        `${phase5 ? "wow animate__animated animate__slideInLeft" : "invisible"}`
                                                    }>
                                                    <img className="img-fluid" src="img/Image5.png" alt="..." />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                `mx-xl-5 px-xl-5 ${phase5 ? "wow animate__animated animate__zoomIn" : "invisible"}`
                                            }>
                                            <span id="phase6"></span>
                                            <div className="row mt-4">
                                                <div className="col-lg-8 offset-lg-2">
                                                    <h3 className="font-frijole text-success text-uppercase">METAVERSE</h3>
                                                    <ul className="font-outfit road__text">
                                                        <li>Scary Teacher’s Scary Home(buy land + create house + levels)</li>
                                                        <li>Charity</li>
                                                        <li>Rent Miss T</li>
                                                        <li>Community driven initiatives</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <span ref={ques5}></span>

                    </div>
                </div>
            </section>

            <section className="overflow-hidden" ref={joinRef} id="community">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <figure className="mb-0 third__pic">
                                <img
                                    src="img/third-block.png"
                                    alt="..."
                                    className="img-fluid"
                                />
                            </figure>
                        </div>
                        <div className="col-xl-6">
                            <h2 className="font-frijole text-success mb-5 fouth__title text-mob-center text-tablet-center">
                                Join The Community
                            </h2>
                            <ul className="nav justify-content-center mb-5">
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={discordIcon}
                                        href="https://discord.gg/Ptx8heAZZd"
                                        target="_blank"
                                    >
                                        <img src="img/ico-discord-lg.svg" alt="..." />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={twitterIcon}
                                        href="https://twitter.com/TeacherScary"
                                        target="_blank"
                                    >
                                        <img src="img/ico-twitter-lg.svg" alt="..." />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={youtubeIcon}
                                        href="https://www.youtube.com/channel/UC6z4RnDkI5DALyx3M1qEmPQ"
                                        target="_blank"
                                    >
                                        <img src="img/ico-youtube-lg.svg" alt="..." />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link px-2"
                                        role="button"
                                        onClick={facebookIcon}
                                        href="https://www.facebook.com/Scary-Teacher-104953160859907"
                                        target="_blank"
                                    >
                                        <img src="img/ico-facebook-lg.svg" alt="..." />
                                    </a>
                                </li>
                            </ul>
                            <figure
                                className="mb-0 text-mob-center text-tablet-center  wow animate__animated animate__fadeInUp">
                                <img src="img/ruler-triangle-foot.svg" alt="..." />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container py-4">
                    <hr />
                    <div className="row mt-4">
                        <div className="col-md-6">
                             <a 
                                         className="nav-link text-white py-0"
                                         target="_blank"
                                         href="https://znkgames.com/">
                                        &copy; 2022, ZNK. All Rights Reserved
                                        </a>
                        </div>
                        <div className="col-md-6">
                            <nav className="nav justify-content-center justify-content-md-end font-outfit nav__footer">
                                <a
                                    className="nav-link text-white py-0"
                                    role="button"
                                    onClick={privacyPolicy}
                                    href="https://znkgames.com/privacy.html"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </a>
                                <a
                                    className="nav-link text-white py-0"
                                    role="button"
                                    onClick={terms}
                                    href="https://znkgames.com/tos.html"
                                    target="_blank"
                                >
                                    Terms & Conditions
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
